import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { device } from "../utils/breakpoints"
import { isBrowser, isMobile } from "react-device-detect"

const getBackground = graphql`
  query {
    default1: file(relativePath: { eq: "intro.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    defaultMobile: file(relativePath: { eq: "heyer-intro.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const StyledHero = ({ img, children, className, home }) => {
  const data = useStaticQuery(getBackground)
  const defaultImage = () => {
    if (isBrowser) {
      return data.default1.childImageSharp.fluid
    } else {
      return data.defaultMobile.childImageSharp.fluid
    }
  }
  return (
    <BackgroundImage
      fluid={img || defaultImage()}
      className={className}
      home={home}
    >
      {children}
    </BackgroundImage>
  )
}

export default styled(StyledHero)`
  min-height: ${props => (props.home ? "calc(50vh);" : "30vh")};
  min-height: ${isMobile && "30vh"};
  background: linear-gradient(rgba(1, 96, 157, 0.2), rgba(12, 56, 89, 0.5));
  background-position: center;

  background-size: cover;
  opacity: 1 !important;
  justify-content: flex-end;
  align-items: center;
  display: flex;

  @media ${device.tablet} {
    p {
      width: 70%;
    }
    .gatsby-image-wrapper {
      width: 300px;
    }
  }
`
